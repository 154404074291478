<template>
  <div class="p-3">
    <TitleBar
      >Campaign Dashboard
      <template #custom>
        <div
          class="flex align-items-center justify-content-end"
          v-if="selectedDate === 'Custom Range'"
        >
          <Calendar
            :showIcon="true"
            :number-of-months="1"
            :max-date="new Date()"
            selection-mode="range"
            v-model="dateRange"
            placeholder="Select a Date Range"
            :manual-input="false"
            date-format="mm/dd/y"
          ></Calendar>

          <Button class="mx-2" label="GO" @click="loadCampaignOverview" />
        </div>

        <div class="flex" v-if="mailings && selectedDate === 'Recent Mailings'">
          <Button class="mx-2" label="Clear" @click="clearSavedMailings" />
          <MultiSelect
            v-model="selectedMailing"
            :options="mailings"
            option-label="formatted_description"
            placeholder="Select Mailings"
            class="mx-1"
            style="max-width: 500px"
          />

          <Button class="mx-2" label="GO" @click="loadCampaignOverview" />
        </div>

        <span class="mx-3" v-if="dataLoading">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </span>

        <Dropdown
          v-model="selectedDate"
          :options="dates"
          option-label="label"
          option-value="value"
          scrollHeight="300px"
          placeholder="Select Date Range"
        />
      </template>
    </TitleBar>

    <div class="mt-2">
      <CampaignStatsFull
        :campaign="campaignOverview"
        v-if="campaignOverview && useFullStats"
        :daysBack="selectedDate"
      />
      <CampaignStats
        :campaign="campaignOverview"
        v-if="campaignOverview && !useFullStats"
        :daysBack="selectedDate"
      />
    </div>

    <div
      class="mt-2"
      v-if="campaignOverview && campaignOverview.included_mailings"
    >
      <div class="grid px-4" v-if="chartsLayout && chartsLayout">
        <ChartCard
          v-for="chart in chartsLayout"
          :key="chart.field"
          :chart="chart"
          :horizontal="chart.horizontal || false"
          :mailing-id="campaignOverview.included_mailing_ids"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CampaignOverview from "@/models/CampaignOverview";
import CampaignStats from "@/components/CampaignStats";
import CampaignStatsFull from "@/components/CampaignStatsFull";
import Mailing from "@/models/Mailing";
import ChartCard from "@/components/ChartCard";
import TitleBar from "@/components/TitleBar.vue";
import ChartLayout from "@/models/ChartLayout";
import { useAppStore } from "@/stores/app";
export default {
  name: "CampaignDashboard.vue",
  components: { CampaignStatsFull, CampaignStats, ChartCard, TitleBar },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      selectedDate: 90,
      mailings: [],
      selectedMailing: null,
      dates: [
        { label: "Last 30 Days", value: 30 },
        { label: "Last 60 Days", value: 60 },
        { label: "Last 90 Days", value: 90 },
        { label: "Custom Range", value: "Custom Range" },
        { label: "Recent Mailings", value: "Recent Mailings" },
      ],
      startDate: null,
      endDate: null,
      dateRange: [],
      campaignOverview: null,
      dataLoading: false,
      chartsLayout: null,
    };
  },
  computed: {
    campaign() {
      return this.appStore.currentCampaign;
    },
    useFullStats() {
      return process.env.VUE_APP_FULL_STATS_OVERVIEW === "true";
    },
  },
  watch: {
    selectedDate() {
      if (
        this.selectedDate !== "Recent Mailings" &&
        this.selectedDate !== "Custom Range"
      ) {
        this.loadCampaignOverview();
      }
    },
    startDate() {
      console.log(this.startDate);
    },
  },
  async mounted() {
    await this.loadMailings();
    await this.loadChartLayouts();
    await this.loadSavedMailings();
    await this.loadCampaignOverview();
  },
  methods: {
    loadCampaignOverview() {
      this.dataLoading = true;

      let campaignRequest;

      if (this.selectedDate === "Custom Range") {
        campaignRequest = CampaignOverview.where(
          "date_range",
          this.dateRange.toString()
        );
      } else if (this.selectedDate === "Recent Mailings") {
        campaignRequest = CampaignOverview.whereIn(
          "mailing_id",
          this.selectedMailing.map((data) => data.id)
        );
        this.saveLocalMailings();
      } else {
        campaignRequest = CampaignOverview.where("days", this.selectedDate);
      }

      campaignRequest
        .find(this.campaign.id)
        .then((response) => {
          this.campaignOverview = response.data;
        })
        .catch()
        .then(() => (this.dataLoading = false));
    },
    async clearSavedMailings() {
      try {
        localStorage.removeItem("savedMailings" + this.campaign.id);
        this.selectedMailing = null;
        this.selectedDate = 90;
      } catch (e) {
        alert("Issue clearling");
      }
    },
    async loadMailings() {
      await Mailing.limit(60)
        .where("campaign_id", this.appStore.currentCampaign.id)
        .get()
        .then((response) => {
          this.mailings = response.data;
        });
    },
    async loadChartLayouts() {
      await ChartLayout.where("campaign_id", this.campaign.id)
        .where("type", "campaign")
        .limit(1)
        .first()
        .then((data) => {
          console.log(data.charts);
          this.chartsLayout = JSON.parse(data.charts);
        });
    },
    saveLocalMailings() {
      const parsed = JSON.stringify(this.selectedMailing);
      localStorage.setItem("savedMailings" + this.campaign.id, parsed);
    },
    async loadSavedMailings() {
      if (localStorage.getItem("savedMailings" + this.campaign.id)) {
        try {
          this.selectedMailing = JSON.parse(
            localStorage.getItem("savedMailings" + this.campaign.id)
          );
          this.selectedDate = "Recent Mailings";
        } catch (e) {
          localStorage.removeItem("savedMailings" + this.campaign.id);
        }
      }
    },
  },
};
</script>
